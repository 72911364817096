@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400&display=swap');
@import './styles/custom-scrollbar.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* theme colors */
  --mono-0: #000;
  --mono-1: #fff;
  --gray-1: #333;
  --gray-2: #727275;
  --gray-3: #ababab;
  --gray-4: #e1e1e0;
  --gray-5: #f7f7f7;
  --primary-1: #00398f;
  --primary-2: #0052cc;
  --primary-3: #4d86db;
  --primary-4: #99baeb;
  --primary-5: #e6eefa;
  --error: #f01616;
  --warning: #ff6914;
  --success: #53ae0c;

  /* connecting line */
  --connecting-line: #7d7d7d;

  /* control colors */
  --control-default-bg: #e6eefa;
  --control-default-border: #99baeb;
  --control-critical-bg: #99baeb;
  --control-critical-border: #0052cc;
  --control-issue-bg: #ffc1c1;
  --control-issue-border: red;

  /* causes area */
  --causes-bg: transparent;
  --causes-border: #f4d03f;
  --causes-box-bg: #e1e1e0;
  --causes-box-border: #ababab;
  --causes-box-fg: #333;

  /* preventative controls area */
  --pc-bg: transparent;
  --pc-border: #3498db;
  /* pc default */
  --pc-default-bg: var(--control-default-bg);
  --pc-default-border: var(--control-default-border);
  --pc-default-fg: #333;
  /* pc critical effective */
  --pc-critical-effective-bg: var(--control-critical-bg);
  --pc-critical-effective-border: var(--control-critical-border);
  --pc-critical-effective-fg: #333;
  /* pc critical non_ffective */
  --pc-critical-non_effective-bg: var(--control-issue-bg);
  --pc-critical-non_effective-border: var(--control-issue-border);
  --pc-critical-non_effective-fg: #333;
  /* pc non_critical effective */
  --pc-non_critical-effective-bg: var(--control-default-bg);
  --pc-non_critical-effective-border: var(--control-default-border);
  --pc-non_critical-effective-fg: #333;
  /* pc non_critical non_ffective */
  --pc-non_critical-non_effective-bg: var(--control-issue-bg);
  --pc-non_critical-non_effective-border: var(--control-issue-border);
  --pc-non_critical-non_effective-fg: #333;

  /* hazard box */
  --hazard-bg: #ababab;
  --hazard-border: #727275;
  --hazard-fg: #333;

  /* material unwanted event box */
  --mue-bg: #99baeb;
  --mue-border: #727275;
  --mue-fg: #333;

  /* mitigating controls area */
  --mc-bg: transparent;
  --mc-border: #3498db;
  /* mc default */
  --mc-default-bg: var(--control-default-bg);
  --mc-default-border: var(--control-default-border);
  --mc-default-fg: #333;
  /* mc critical effective */
  --mc-critical-effective-bg: var(--control-critical-bg);
  --mc-critical-effective-border: var(--control-critical-border);
  --mc-critical-effective-fg: #333;
  /* mc critical non_ffective */
  --mc-critical-non_effective-bg: var(--control-issue-bg);
  --mc-critical-non_effective-border: var(--control-issue-border);
  --mc-critical-non_effective-fg: #333;
  /* mc non_critical effective */
  --mc-non_critical-effective-bg: var(--control-default-bg);
  --mc-non_critical-effective-border: var(--control-default-border);
  --mc-non_critical-effective-fg: #333;
  /* mc non_critical non_ffective */
  --mc-non_critical-non_effective-bg: var(--control-issue-bg);
  --mc-non_critical-non_effective-border: var(--control-issue-border);
  --mc-non_critical-non_effective-fg: #333;

  /* consequences area */
  --consequences-bg: transparent;
  --consequences-border: #ec7063;
  --consequences-box-bg: #e1e1e0;
  --consequences-box-border: #ababab;
  --consequences-box-fg: #333;
}

/* React Flow diagram styles */
.react-flow__edges svg {
  @apply bt-z-[1] !important;
}

/* the 'parent' class is added by react-flow */
.react-flow__node:not(.parent) {
  @apply bt-z-[2] !important;
}

.react-flow__node:not(.parent):has(.node-record-list) {
  /*  ensure the list is shown on top of any other node */
  @apply bt-z-[3] !important;
}

.react-flow__node.react-flow__node-causes-container,
.react-flow__node.react-flow__node-consequences-container,
.react-flow__node.react-flow__node-mitigating-controls-container,
.react-flow__node.react-flow__node-preventative-controls-container {
  @apply bt-z-0 !important;
}

.react-flow__edge-path {
  @apply bt-stroke-cline;
  stroke-linejoin: round;
  stroke-linecap: round;
}

.react-flow__edge-base-edge path,
.react-flow__edge-base-edge circle {
  pointer-events: all;
}

.react-flow__handle {
  @apply bt-cursor-default !important;
  @apply bt-border-cline bt-bg-mono-1;
  @apply bt-h-1 bt-w-1;
}

[data-node-type='mue-node'] .react-flow__handle.react-flow__handle-left,
[data-node-type='mue-node'] .react-flow__handle.react-flow__handle-right {
  @apply bt-h-4 bt-w-4;
}

/* Show 'add button' on main-path edge hover */
.react-flow__edge-base-edge g.add-node {
  @apply bt-invisible;
}
.react-flow__edge-base-edge g.add-node:hover,
.react-flow__edge-base-edge .react-flow__edge-path.main-path + .react-flow__edge-interaction:hover ~ g.add-node {
  @apply bt-visible;
}

/* Custom diagram controls */
.diagram-controls .react-flow__controls-button svg {
  @apply bt-max-h-full bt-max-w-full;
}

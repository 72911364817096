/* FF */
@supports not selector(::-webkit-scrollbar) {
  .custom-scroll {
    --scrollbar-thumb-bg: transparent;
    scrollbar-color: rgba(171, 171, 171, 0.5) transparent;
    scrollbar-width: thin;
  }
}
/* Class to change scrollbar color */
.scrollbar-colored {
  --scrollbar-thumb-bg: rgba(171, 171, 171, 0.5);
}

/* Chrome, Safari, Edge */
.custom-scroll::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  border-color: transparent;
  border-style: solid;
  background-color: var(--scrollbar-thumb-bg);
  background-clip: padding-box;
  border-radius: 7px;
  border-width: 1px;
}

.custom-scroll::-webkit-scrollbar-button {
  display: none;
  width: 0;
  height: 0;
}

.custom-scroll::-webkit-scrollbar-corner {
  background-color: transparent;
}

@media (hover: hover) {
  /* activate, if BG for the whole bar is necessary */
  /* .customScroll:hover::-webkit-scrollbar {
    background-color: rgba(171, 171, 171, 0.3);
  } */

  @supports not selector(::-webkit-scrollbar) {
    .custom-scroll:hover {
      scrollbar-color: rgba(171, 171, 171, 0.5) transparent;
    }
  }

  .custom-scroll:hover::-webkit-scrollbar-thumb {
    background-color: rgba(171, 171, 171, 0.5);
 }
}
